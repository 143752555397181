<template>
	<VueApexCharts
		type="bar"
		:width="width ? width : '100%'"
		:height="height"
		:options="chartOptions"
		:series="series"
	></VueApexCharts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
	name: "GraficoBarras",
	components: { VueApexCharts },
	props: [
		"dataSeries",
		"distributed",
		"categories",
		"horizontal",
		"palette",
		"money",
		"height",
		"width",
		"dataLabels",
		"porcentagem",
		"composto",
		"legenda",
		"labels",
		"color",
		"colorLabel",
		"grid",
		"mobile",
		"tooltip",
	],
	data: () => ({
		series: [],
		chartOptions: {
      // annotations: {
      //   yaxis: [
      //     {
      //       y: 100,  // Valor do eixo Y onde a anotação será colocada
      //       borderColor: '#FEB019',
      //       label: {
      //         borderColor: '#00E396',
      //         style: {
      //           color: '#fff',
      //           background: '#00E396'
      //         },
      //         text: 'Meta de vendas'
      //       }
      //     }
      //   ],
      //   xaxis: [
      //     {
      //       x: 'Mar',  // Valor do eixo X onde a anotação será colocada
      //       borderColor: '#775DD0',
      //       label: {
      //         borderColor: '#775DD0',
      //         style: {
      //           color: '#fff',
      //           background: '#775DD0'
      //         },
      //         text: 'Março'
      //       }
      //     }
      //   ]
      // },
			responsive: [
				{
					breakpoint: 700,
					options: {
						yaxis: {
							show: false,
							forceNiceScale: true,
						},
						xaxis: {
							position: "bottom",
							labels: {
								trim: true,
							},
						},
						dataLabels: {
							enabled: false,
						},
					},
				},
        // {
        //   breakpoint: 768,  // Configurações para tablets
        //   options: {
        //     chart: {
        //       width: '100%',
        //       height: '300px'
        //     },
        //     legend: {
        //       position: 'bottom'
        //     }
        //   }
        // },
        // {
        //   breakpoint: 480,  // Configurações para dispositivos móveis
        //   options: {
        //     chart: {
        //       width: '100%',
        //       height: '200px'
        //     },
        //     legend: {
        //       position: 'bottom'
        //     }
        //   }
        // }
			],
			chart: {
				type: "bar",
				// height: 100,
				height: "100%",  // Mantém o gráfico responsivo
        width: "100%",
				toolbar: {
					show: false,  // Mostra opções de interação como exportar, zoom, etc.
				},
        animations: {
          enabled: true,
          easing: 'easeinout',  // Tipo de animação ('linear', 'easein', 'easeout', etc.)
          speed: 800,  // Duração da animação
          animateGradually: {
            enabled: true,
            delay: 150  // Tempo de espera antes de animar a próxima coluna
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350  // Velocidade da animação durante interações dinâmicas
          }
        }
			},
      plotOptions: {
        bar: {
          borderRadius: 5, // Adiciona bordas arredondadas às colunas
          dataLabels: {},
          horizontal: false, // Define o gráfico de colunas (coluna vertical)
          columnWidth: "80%",
          distributed: true, // Colore cada coluna de forma diferente
          endingShape: "rounded",
        },
      },
			fill: {
				// type: "gradient", // Pode ser 'solid', 'gradient', 'pattern'
				gradient: {
					shadeIntensity: 0.75,
					opacityFrom: 0.9,
					opacityTo: 0.95,
					stops: [0, 10, 100],
				},
			},
      markers: {
        size: 5,  // Tamanho do marcador
        colors: ['#FFA41B'],  // Cor do marcador
        strokeColor: '#00BAEC',  // Cor da borda do marcador
        strokeWidth: 2,  // Largura da borda
        hover: {
          size: 7  // Tamanho do marcador ao passar o mouse
        }
      },
			colors: ["#002D56", "#1764AA", "#45A5FC"],
      grid: {
        show: true,
        borderColor: '#90A4AE',
        strokeDashArray: 1,  // Linhas tracejadas
        position: 'back'
      },
			theme: {
				palette: "",
			},
      legend: {
        show: false,
        position: 'top',  // Posição da legenda ('top', 'right', 'bottom', 'left')
        horizontalAlign: 'center',  // Alinhamento da legenda
        labels: {
          colors: '#9C27B0',
          useSeriesColors: true
        }
      },
      dataLabels: {
        offsetY: 0,
        // style: {
        //   fontSize: '18px', // Aumenta o tamanho da fonte dos valores
        //   fontWeight: 'bold', // Deixa os valores em negrito
        //   colors: ['#000'], // Cor dos valores
        // },
        // background: {
        //   enabled: true,
        //   borderColor: "#fff",
        //   opacity: 0.6,
        // },
        // enabled: true,  // Exibe os valores diretamente nas colunas
        // style: {
        //   colors: ['#304758'],  // Cor dos rótulos
        //   fontSize: '50px',
        //   fontWeight: 'bold'
        // },
        // formatter: function (val) {
        //   return val + "%sdfsd%";  // Customiza o texto dos rótulos (ex.: adicionar %)
        // }
      },
			stroke: {
				show: true,
				width: 1,
				colors: ["transparent"],
        // colors: ["#001b38"],  // Cor da borda
        dashArray: 0  // Define se a borda é contínua ou tracejada
			},
			xaxis: {
				categories: "",
				labels: {
					show: true,
					rotate: -50,
					hideOverlappingLabels: false,
					trim: false,
				},
			},
			yaxis: {
				labels: {
					show: false,
				},
        // min: 0,  // Valor mínimo
        // max: 100, // Valor máximo
        // tickAmount: 10, // Define a quantidade de marcas no eixo Y (opcional)
			},
			tooltip: {
				y: {},
			},
		},
	}),
	created() {
		this.series = this.dataSeries;
		this.chartOptions.plotOptions.bar.horizontal = this.horizontal;
		this.chartOptions.plotOptions.bar.distributed = this.distributed;
		this.chartOptions.plotOptions.bar.dataLabels = {
			position: this.dataLabels.position,
			orientation: this.dataLabels.orientation,
		};
		if (this.tooltip) {
			this.chartOptions.tooltip = this.tooltip;
		}
		this.chartOptions.theme.palette = this.palette;
		this.chartOptions.xaxis.categories = this.categories;
		if (this.labels) {
			this.chartOptions.xaxis.labels.rotate = 0;
		}
		if (this.mobile) {
			this.chartOptions.responsive[0] = this.mobile;
		}
		if (this.porcentagem) {
			this.chartOptions.tooltip.y = {
				enabled: true,
				formatter: function (val) {
					return `${parseFloat(val).toFixed(2)} %`;
				},
			};
			this.chartOptions.dataLabels = {
				enabled: true,
				formatter: function (val) {
					return `${parseFloat(val).toFixed(2)} %`;
				},
				style: {
          fontSize: '17px', // Aumenta o tamanho da fonte dos valores
          fontWeight: 'bold', // Deixa os valores em negrito
					colors: ["#002D56"],
				},
				background: {
					enabled: true,
					borderColor: "#fff",
					opacity: 0.6,
				},
        offsetY: -25,
			};
		} else if (this.money) {
			this.chartOptions.tooltip.y = {
				enabled: true,
				formatter: function (val) {
					return parseFloat(val).toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					});
				},
			};
			this.chartOptions.dataLabels = {
				enabled: true,
				formatter: function (val) {
					return parseFloat(val).toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					});
				},
				style: {
					colors: ["#002D56"],
				},
				background: {
					enabled: true,
					borderColor: "#fff",
					opacity: 0.6,
				},
			};
		} else if (this.composto) {
			this.chartOptions.tooltip.y = {
				enabled: true,
				formatter: function (val, data) {
					return `${parseFloat(val).toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					})} - ${(
						(val /
							data.w.globals.series[0].reduce((a, v) => a + v)) *
						100
					).toFixed(2)}%`;
				},
			};
			this.chartOptions.dataLabels = {
				enabled: true,
				formatter: function (val, data) {
					return `${(
						(val /
							data.w.globals.series[0].reduce((a, v) => a + v)) *
						100
					).toFixed(2)}%`;
				},
				style: {
					colors: ["#002D56"],
          fontSize: '17px', // Aumenta o tamanho da fonte dos valores
          fontWeight: 'bold', // Deixa os valores em negrito
				},
				background: {
					enabled: true,
					borderColor: "#fff",
					opacity: 0.6,
				},
			};
		} else if (this.legenda) {
			this.chartOptions.tooltip.y = {
				enabled: true,
				formatter: function (val) {
					return `${val}`;
				},
			};
			this.chartOptions.dataLabels = {
				enabled: true,
				formatter: function (val, data) {
					return `${
						data.w.globals.labels[data.dataPointIndex]
					}: ${val}`;
				},
				style: {
					colors: ["#002D56"],
          fontSize: '18px', // Aumenta o tamanho da fonte dos valores
          fontWeight: 'bold', // Deixa os valores em negrito
				},
				background: {
					enabled: true,
					borderColor: "#fff",
					opacity: 0.6,
				},
			};
		} else {
			this.chartOptions.tooltip.y = {
				enabled: true,
			};
			this.chartOptions.dataLabels = {
				enabled: true,
				style: {
					colors: ["#002D56"],
          fontSize: '16px', // Aumenta o tamanho da fonte dos valores
          fontWeight: 'bold', // Deixa os valores em negrito
				},
				background: {
					enabled: true,
					borderColor: "#fff",
					opacity: 0.6,
				},
			};
		}
		if (this.dataLabels.offsetY) {
			this.chartOptions.dataLabels.offsetY = this.dataLabels.offsetY;
		}
		if (this.grid == "clean") {
			this.chartOptions.grid.show = false;
		}
		if (this.color) {
			this.chartOptions.colors = this.color;
		}
		if (this.colorLabel) {
			this.chartOptions.dataLabels.style.colors = this.colorLabel;
		}
	},
};
</script>

<style>
.apexcharts-tooltip {
	background: #ffffff !important;
	color: #023449;
	border-color: #023449 !important;
}

.apexcharts-tooltip-title {
	background-color: #023449 !important;
	color: #ffffff;
}
</style>
