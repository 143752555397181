<template>
	<VueApexCharts type="radialBar" :height="height" :options="chartOptions" :series="series"></VueApexCharts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
	name: "GraficoPonteiro",
	components: { VueApexCharts },
	props: ["dataSeries", "height"],
	data: () => ({
    valueMax: 100,
		series: [],
    chartOptions: {
      colors: ["#50A2E0"],
      chart: {
        type: 'radialBar',
        offsetY: -30,
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          hollow: {
            margin: 15,
            size: '45%',     // Tamanho oco no centro
            background: 'transparent', // Fundo transparente
          },
          track: {
            background: "#e7e7e7",
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: '#999',
              opacity: 1,
              blur: 2
            },
          },
          dataLabels: {
            name: {
              show: false,
              fontSize: '20px'
            },
            value: {
              offsetY: -5,
              show: true,
              fontWeight: 'bold',
              fontSize: '26px',
              color: '#13456B'
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'horizontal',
          shadeIntensity: 0.1,
          inverseColors: false,
          gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0,50, 100],
          colorStops: []
        },
      },
    },
	}),
	methods: {
		init() {
      this.series[0] = this.dataSeries;
		},
	},
	created() {
		this.init();
	},
};
</script>
