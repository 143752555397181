<template>
	<v-row>
    <v-dialog v-model="dialog.status" fullscreen class="fundo">
      <v-card class="fundo overflow-hidden">
        <v-row class="pa-6">
          <v-col cols="3" class="pa-0">
            <v-col cols="12">
              <v-card class="v-card-conteudo v-card-logo elevation-3">
                <v-card-title class="v-card-titulo py-5">
                  <v-img
                      class="mb-n1"
                      style="margin: 0 auto;"
                      max-height="100"
                      max-width="194"
                      src="../../assets/icons/Gestor_Logo_.svg"
                  ></v-img>
                </v-card-title>
              </v-card>
            </v-col>
            <transition :name="shouldAnimate ? 'slide-up' : ''">
              <div v-show="!flagDia">
                <v-col cols="12">
                  <v-card class="v-card-conteudo" style="position: relative">
                    <v-card-title class="v-card-titulo">
                      <v-icon size="30" color="primary" class="mr-2">mdi-bullseye-arrow</v-icon> Atingimento regional (MÊS)
                    </v-card-title>
                    <v-divider/>
                    <v-col v-if="carregando || !dashboard.vendas_filiais" cols="12" class="text-center" style="position: absolute; bottom: 36px">
                      <v-progress-circular indeterminate size="90" color="primary"></v-progress-circular>
                    </v-col>
                    <v-card-text>
                      <v-skeleton-loader elevation="2" v-if="carregando || !dashboard.vendas_filiais" class="ma-auto" width="100%" height="156px" type="image, image"></v-skeleton-loader>
                      <GraficoPonteiro
                          v-else
                          :maxValue="100"
                          :key="JSON.stringify(dashboard.vendas_filiais)"
                          :dataSeries="(dashboard.vendas_filiais.map(v => v.venda).reduce((a,v) => a + v) / dashboard.vendas_filiais.map(v => v.meta_ac_dia).reduce((a,v) => a + v) * 100).toFixed(2)"
                          :height="'310px'"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card class="v-card-conteudo" style="position: relative">
                    <v-card-title class="v-card-titulo">
                      <v-icon size="30" color="primary" class="mr-2">mdi-chart-line-variant</v-icon> Margem regional (MÊS)
                    </v-card-title>
                    <v-divider/>
                    <v-col v-if="carregando || !dashboard.vendas_filiais" cols="12" class="text-center" style="position: absolute; bottom: 36px">
                      <v-progress-circular indeterminate size="90" color="primary"></v-progress-circular>
                    </v-col>
                    <v-card-text>
                      <v-skeleton-loader elevation="2" v-if="carregando || !dashboard.vendas_filiais" class="ma-auto" width="100%" height="156px" type="image, image"></v-skeleton-loader>
                      <GraficoPonteiro
                          v-else
                          :maxValue="30"
                          :key="JSON.stringify(dashboard.vendas_filiais)"
                          :dataSeries="(dashboard.vendas_filiais.map(v => v.lucro).reduce((a,v) => a + v) / dashboard.vendas_filiais.map(v => v.venda).reduce((a,v) => a + v) * 100).toFixed(2)"
                          :height="'310px'"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card class="v-card-conteudo" style="position: relative">
                    <v-card-title class="v-card-titulo">
                      <v-icon size="30" color="primary" class="mr-2">mdi-sale</v-icon> Ticket Médio (mês)
                    </v-card-title>
                    <v-divider/>
                    <v-col v-if="carregando || !dashboard.vendas_filiais" cols="12" class="text-center" style="position: absolute; bottom: 110px">
                      <v-progress-circular indeterminate size="100" color="primary"></v-progress-circular>
                    </v-col>
                    <v-card-text>
                      <v-skeleton-loader elevation="2" v-if="carregando || !dashboard.vendas_filiais" class="ma-auto" width="100%" height="314px" type="image, image"></v-skeleton-loader>
                      <GraficoPizza
                          v-else
                          :key="JSON.stringify(dashboard.vendas_filiais)"
                          :dataSeries="dashboard.vendas_filiais.map((v) => parseFloat(v.ticketmedio.toFixed(2)))"
                          :labels="dashboard.vendas_filiais.map((v) => v.empresa)"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
              </div>
            </transition>
            <transition :name="shouldAnimate ? 'slide-up' : ''">
              <div v-show="flagDia">
                <v-col cols="12">
                  <v-card class="v-card-conteudo" style="position: relative">
                    <v-card-title class="v-card-titulo">
                      <v-icon size="30" color="primary" class="mr-2">mdi-bullseye-arrow</v-icon> Atingimento regional (DIA)
                    </v-card-title>
                    <v-divider/>
                    <v-col v-if="carregando || !dashboard.vendas_filiais" cols="12" class="text-center" style="position: absolute; bottom: 36px">
                      <v-progress-circular indeterminate size="90" color="primary"></v-progress-circular>
                    </v-col>
                    <v-card-text>
                      <v-skeleton-loader elevation="2" v-if="carregando || !dashboard.vendas_filiais" class="ma-auto" width="100%" height="156px" type="image, image"></v-skeleton-loader>
                      <GraficoPonteiro
                          v-else
                          :key="JSON.stringify(dashboard.vendas_filiais)"
                          :dataSeries="(dashboard.vendas_filiais.map(v => v.venda_dia).reduce((a,v) => a + v) / dashboard.vendas_filiais.map(v => v.meta_dia).reduce((a,v) => a + v) * 100).toFixed(2)"
                          :height="'310px'"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card class="v-card-conteudo" style="position: relative">
                    <v-card-title class="v-card-titulo">
                      <v-icon size="30" color="primary" class="mr-2">mdi-chart-line-variant</v-icon> Margem regional (DIA)
                    </v-card-title>
                    <v-divider/>
                    <v-col v-if="carregando || !dashboard.vendas_filiais" cols="12" class="text-center" style="position: absolute; bottom: 36px">
                      <v-progress-circular indeterminate size="90" color="primary"></v-progress-circular>
                    </v-col>
                    <v-card-text>
                      <v-skeleton-loader elevation="2" v-if="carregando || !dashboard.vendas_filiais" class="ma-auto" width="100%" height="156px" type="image, image"></v-skeleton-loader>
                      <GraficoPonteiro
                          v-else
                          :key="JSON.stringify(dashboard.vendas_filiais)"
                          :dataSeries="(dashboard.vendas_filiais.map(v => v.lucro_dia).reduce((a,v) => a + v) / dashboard.vendas_filiais.map(v => v.venda_dia).reduce((a,v) => a + v) * 100).toFixed(2)"
                          :height="'310px'"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card class="v-card-conteudo" style="position: relative">
                    <v-card-title class="v-card-titulo">
                      <v-icon size="30" color="primary" class="mr-2">mdi-sale</v-icon> Ticket Médio (DIA)
                    </v-card-title>
                    <v-divider/>
                    <v-col v-if="carregando || !dashboard.vendas_filiais" cols="12" class="text-center" style="position: absolute; bottom: 110px">
                      <v-progress-circular indeterminate size="100" color="primary"></v-progress-circular>
                    </v-col>
                    <v-card-text>
                      <v-skeleton-loader elevation="2" v-if="carregando || !dashboard.vendas_filiais" class="ma-auto" width="100%" height="314px" type="image, image"></v-skeleton-loader>
                      <GraficoPizza
                          v-else
                          :key="JSON.stringify(dashboard.vendas_filiais)"
                          :dataSeries="dashboard.vendas_filiais.map((v) => parseFloat(v.ticketmedio_dia.toFixed(2)))"
                          :labels="dashboard.vendas_filiais.map((v) => v.empresa)"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
              </div>
            </transition>
          </v-col>
          <v-col cols="9" class="pa-0">
            <v-col cols="12">
              <v-card class="v-card-regional elevation-3">
                <v-card-title class="v-card-titulo-regional pa-2">
                  <v-btn :disabled="carregando" @click="listar('-')" icon dark><v-icon size="30">mdi-chevron-left</v-icon></v-btn>
                  <v-spacer/>
                  <v-icon size="35" class="mr-3 mb-1" dark>mdi-account-supervisor</v-icon> REGIONAL 0{{idregional}} — {{ regionais [idregional-1]}}
                  <v-spacer/>
                  <v-btn :disabled="carregando" @click="listar('+')" icon dark><v-icon size="30">mdi-chevron-right</v-icon></v-btn>
<!--                  {{(countdown / 300 * 30).toFixed(0)}}-->
                </v-card-title>
                <v-divider dark />
                <v-col cols="12" class="pa-0">
                  <v-tabs
                      height="40px"
                      background-color="transparent"
                      grow
                      dark
                      v-model="tab"
                      hide-slider
                  >
<!--                    <v-tabs-slider style="height: 10px !important;" color="rgba(255, 255, 255, 0.90)"></v-tabs-slider>-->
                    <v-tab
                        @click="resetCountdown(0, 'M')"
                        :disabled="carregando"
                        active-class="white--text font-weight-black"
                    >
                      <span class="mb-1">
                        <v-icon size="21" class="mr-2">mdi-calendar-month</v-icon>Mês
                      </span>
                      <v-progress-linear rounded :value="(countdown / (countdownId/2)) * 100" absolute bottom color="white"></v-progress-linear>
                    </v-tab>

                    <v-divider vertical />
                    <v-tab
                        @click="resetCountdown(1, 'M')"
                        :disabled="carregando"
                        active-class="white--text font-weight-black"
                    >
                      <span class="mb-1">
                        <v-icon size="21" class="mr-2">mdi-calendar-today</v-icon>Dia
                      </span>
                      <v-progress-linear rounded :value="countdown>=(countdownId/2) ? (((countdown-(countdownId/2)) / (countdownId/2)) * 100) : 0" absolute bottom color="white"></v-progress-linear>
                    </v-tab>
                  </v-tabs>
                </v-col>
              </v-card>
            </v-col>
            <transition :name="shouldAnimate ? 'slide-up' : ''">
              <v-row no-gutters v-show="!flagDia">
                <v-col cols="7" class="pa-0">
                  <v-col cols="12" v-if="true">
                    <v-card class="v-card-conteudo" style="position: relative">
                      <v-card-title class="v-card-titulo">
                        <v-icon size="30" color="primary" class="mr-2">mdi-bullseye-arrow</v-icon> Atingimento filiais (MÊS)
                      </v-card-title>
                      <v-divider/>
                      <v-col v-if="carregando || !dashboard.vendas_filiais" cols="12" style="position: absolute; bottom: 80px" class="text-center">
                        <v-progress-circular indeterminate size="120" color="primary"></v-progress-circular>
                      </v-col>
                      <v-card-text :class="carregando ? '' : 'pa-0 px-3'">
                        <v-skeleton-loader elevation="2" v-if="carregando || !dashboard.vendas_filiais" class="ma-auto" width="100%" height="282px" type="image, image"></v-skeleton-loader>
                        <GraficoBarras
                            v-else
                            :key="JSON.stringify(dashboard.vendas_filiais)"
                            :dataSeries="[
                              {
                                  name: 'Atingimento',
                                  data: dashboard.vendas_filiais.map((v) => v.atingimento.toFixed(2)),
                              },
                          ]"
                            :categories="dashboard.vendas_filiais.map((v) => v.empresa)"
                            height="300"
                            :money="false"
                            :porcentagem="true"
                            :distributed="true"
                            :dataLabels="{
                                      position: 'top',
                                      orientation: 'horizontal',
                                      offsetY: -25,
                                  }"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col> <!-- tamanho antigo -->
                  <v-col cols="12" v-if="false">
                    <v-card class="v-card-conteudo" style="position: relative">
                      <v-card-title class="v-card-titulo">
                        <v-icon size="30" color="primary" class="mr-2">mdi-bullseye-arrow</v-icon> Atingimento filiais (MÊS)
                      </v-card-title>
                      <v-divider/>
                      <v-col v-if="carregando || !dashboard.vendas_filiais" cols="12" style="position: absolute; bottom: 80px" class="text-center">
                        <v-progress-circular indeterminate size="120" color="primary"></v-progress-circular>
                      </v-col>
                      <v-card-text :class="carregando ? '' : 'pa-0 px-3'">
                        <v-skeleton-loader elevation="2" v-if="carregando || !dashboard.vendas_filiais" class="ma-auto" width="100%" height="282px" type="image, image"></v-skeleton-loader>
                        <GraficoBarras
                            v-else
                            :key="JSON.stringify(dashboard.vendas_filiais)"
                            :dataSeries="[
                              {
                                  name: 'Atingimento',
                                  data: dashboard.vendas_filiais.map((v) => v.atingimento.toFixed(2)),
                              },
                          ]"
                            :categories="dashboard.vendas_filiais.map((v) => v.empresa)"
                            height="380"
                            :money="false"
                            :porcentagem="true"
                            :distributed="true"
                            :dataLabels="{
                                      position: 'top',
                                      orientation: 'horizontal',
                                      offsetY: -25,
                                  }"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" v-if="true"> <!-- tamanho antigo -->
                    <v-card class="v-card-conteudo" style="position: relative">
                      <v-card-title class="v-card-titulo">
                        <v-icon size="30" color="primary" class="mr-2">mdi-chart-line-variant</v-icon> margem filiais (MÊS)
                      </v-card-title>
                      <v-divider/>
                      <v-col v-if="carregando || !dashboard.vendas_filiais" cols="12" style="position: absolute; bottom: 80px" class="text-center">
                        <v-progress-circular indeterminate size="120" color="primary"></v-progress-circular>
                      </v-col>
                      <v-card-text :class="carregando ? '' : 'pa-0 px-3'">
                        <v-skeleton-loader elevation="2" v-if="carregando || !dashboard.vendas_filiais" class="ma-auto" width="100%" height="282px" type="image, image"></v-skeleton-loader>
                        <GraficoBarras
                            v-else
                            :key="JSON.stringify(dashboard.vendas_filiais)"
                            :dataSeries="[
                              {
                                  name: 'Atingimento',
                                  data: dashboard.vendas_filiais.map((v) => v.margem.toFixed(2)),
                              },
                          ]"
                            :categories="dashboard.vendas_filiais.map((v) => v.empresa)"
                            height="300"
                            :money="false"
                            :porcentagem="true"
                            :distributed="true"
                            :dataLabels="{
                                      position: 'top',
                                      orientation: 'horizontal',
                                      offsetY: -25,
                                  }"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" v-if="false">
                    <v-card class="v-card-conteudo" style="position: relative">
                      <v-card-title class="v-card-titulo">
                        <v-icon size="30" color="primary" class="mr-2">mdi-chart-line-variant</v-icon> margem filiais (MÊS)
                      </v-card-title>
                      <v-divider/>
                      <v-col v-if="carregando || !dashboard.vendas_filiais" cols="12" style="position: absolute; bottom: 80px" class="text-center">
                        <v-progress-circular indeterminate size="120" color="primary"></v-progress-circular>
                      </v-col>
                      <v-card-text :class="carregando ? '' : 'pa-0 px-3'">
                        <v-skeleton-loader elevation="2" v-if="carregando || !dashboard.vendas_filiais" class="ma-auto" width="100%" height="282px" type="image, image"></v-skeleton-loader>
                        <GraficoBarras
                            v-else
                            :key="JSON.stringify(dashboard.vendas_filiais)"
                            :dataSeries="[
                              {
                                  name: 'Atingimento',
                                  data: dashboard.vendas_filiais.map((v) => v.margem.toFixed(2)),
                              },
                          ]"
                            :categories="dashboard.vendas_filiais.map((v) => v.empresa)"
                            height="380"
                            :money="false"
                            :porcentagem="true"
                            :distributed="true"
                            :dataLabels="{
                                      position: 'top',
                                      orientation: 'horizontal',
                                      offsetY: -25,
                                  }"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-col>
                <v-col cols="5" class="pa-0">
                  <v-col cols="12">
                    <v-card class="v-card-conteudo">
                      <v-card-title class="v-card-titulo">
                        <v-icon color="primary" class="mr-3 mb-1">mdi-podium</v-icon> Top 5 Vendedores (MÊS)
                      </v-card-title>
                      <v-divider/>
                      <v-card-text :class="carregando ? 'pa-0' : 'px-0 pt-3'">
                        <v-skeleton-loader v-if="carregando || !dashboard.vendas_vendedores" type="table-tbody" />
                        <v-data-table
                            v-else
                            :items-per-page="5"
                            hide-default-footer
                            :items="dashboard.vendas_vendedores"
                            :headers="[
                                  // {text: 'Filial', value: 'empresa', class:'text-truncate', sortable:false},
                                  {text: 'Rk', value: 'medalha', class:'text-truncate pr-0',align:'start', sortable:false},
                                  {text: 'Vendedor', value: 'vendedor', class:'text-truncate pl-0', sortable:false},
                                  {text: 'Total de Venda', value: 'venda', class:'text-truncate', align:'center' , sortable:false},
                              ]"
                        >
                          <template v-slot:item.vendedor="{item}">
                            <td class="pl-0">
                              {{item.vendedor | formataTextoNomeVendedores }}
                            </td>
                          </template>
                          <template v-slot:item.medalha="{item, index }">
                            <td class="pr-0">
                              <v-icon small v-if="index==0" color="yellow accent-4">mdi-medal</v-icon>
                              <v-icon small v-else-if="index==1" color="grey lighten-1">mdi-medal</v-icon>
                              <v-icon small v-else-if="index==2" color="brown darken-1">mdi-medal</v-icon>
                              <span v-else class="ml-2">-</span>
                            </td>
                          </template>
                          <template v-slot:item.venda="{item}">
                            <td class="text-center" width="200px">
                              <v-chip small class="v-chip-top font-weight-medium text-subtitle-2">
                                {{item.venda | formataDinheiro }}
                              </v-chip>
                            </td>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card class="v-card-conteudo">
                      <v-card-title class="v-card-titulo">
                        <v-icon color="primary" class="mr-3 mb-1">mdi-podium</v-icon>Top 5 Categorias (MÊS)
                      </v-card-title>
                      <v-divider/>
                      <v-card-text :class="carregando ? 'pa-0' : 'px-0 pt-3'">
                        <v-skeleton-loader v-if="carregando || !dashboard.vendas_categorias" type="table-tbody" />
                        <v-data-table
                            v-else
                            :items-per-page="5"
                            hide-default-footer
                            :items="dashboard.vendas_categorias"
                            :headers="[
                                  {text: 'Rk', value: 'medalha', class:'text-truncate pr-0',align:'start', sortable:false},
                                  {text: 'Categoria', value: 'secao', class:'text-truncate pl-0', sortable:false},
                                  {text: '% Venda', value: 'pervenda', class:'text-truncate' , align:'center', sortable:false},
                              ]"
                        >
                          <template v-slot:item.secao="{item }">
                            <td class="pl-0">
                              {{item.secao}}
                            </td>
                          </template>
                          <template v-slot:item.medalha="{item, index }">
                            <td class="pr-0">
                              <v-icon small v-if="index==0" color="yellow accent-4">mdi-medal</v-icon>
                              <v-icon small v-else-if="index==1" color="grey lighten-1">mdi-medal</v-icon>
                              <v-icon small v-else-if="index==2" color="brown darken-1">mdi-medal</v-icon>
                              <span v-else class="ml-2">-</span>
                            </td>
                          </template>
                          <template v-slot:item.pervenda="{item}">
                            <td class="text-center" width="200px">
                              <v-chip small class="v-chip-top font-weight-medium text-subtitle-2">
                                {{item.pervenda.toFixed(2)}}%
                              </v-chip>
                            </td>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-col>
                <v-col cols="12" class="pa-0" v-if="true">
                  <v-col cols="12">
                    <v-card class="marquee-wrapper" height="136px">
                      <v-card-title class="v-card-titulo"><v-icon size="30" color="primary" class="mr-2">mdi-television</v-icon> Painel de Desempenho de Vendedores (Mês) </v-card-title>
                      <v-divider/>
                      <v-col v-if="carregando || !dashboard.vendas_filiais" cols="12" class="text-center" style="position: absolute; bottom: 12px">
                        <v-progress-circular indeterminate size="30" color="primary"></v-progress-circular>
                      </v-col>
                      <v-card-text :class="carregando?'':'py-6'">
                        <v-skeleton-loader elevation="2" v-if="carregando || !dashboard.vendas_filiais" class="ma-auto" width="100%" height="52px" type="image, image"></v-skeleton-loader>
                        <div class="marquee py-1" v-if="dashboard.vendas_filiais">
                          <div v-for="(item, index) in repeatedItems" :key="index" class="marquee-item">
                            {{ item.empresa }} - {{ item.vendedor | formataTextoNomeVendedoresCurto }} {{ (item.atingimento * 100).toFixed(2) }}%
                            <v-icon v-if="item.atingimento_dia > 1" color="success" class="mb-1">mdi-arrow-up-bold</v-icon>
                            <v-icon v-else-if="item.atingimento_menos_dia > 1" color="warning" class="mb-1">mdi-minus-thick</v-icon>
                            <v-icon v-else color="error" class="mb-1">mdi-arrow-down-bold</v-icon>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-col>
              </v-row>
            </transition>
            <transition :name="shouldAnimate ? 'slide-up' : ''">
              <v-row no-gutters v-show="flagDia">
                <v-col cols="7" class="pa-0">
                  <v-col cols="12">
                    <v-card class="v-card-conteudo" style="position: relative">
                      <v-card-title class="v-card-titulo">
                        <v-icon size="30" color="primary" class="mr-2">mdi-bullseye-arrow</v-icon> atingimento filiais (DIA)
                      </v-card-title>
                      <v-divider/>
                      <v-col v-if="carregando || !dashboard.vendas_filiais" cols="12" style="position: absolute; bottom: 80px" class="text-center">
                        <v-progress-circular indeterminate size="120" color="primary"></v-progress-circular>
                      </v-col>
                      <v-card-text :class="carregando ? '' : 'pa-0 px-3'">
                        <v-skeleton-loader elevation="2" v-if="carregando || !dashboard.vendas_filiais" class="ma-auto" width="100%" height="282px" type="image, image"></v-skeleton-loader>
                        <GraficoBarras
                            v-else
                            :key="JSON.stringify(dashboard.vendas_filiais)"
                            :dataSeries="[
                              {
                                  name: 'Atingimento',
                                  data: dashboard.vendas_filiais.map((v) => v.atingimento_dia.toFixed(2)),
                              },
                          ]"
                            :categories="dashboard.vendas_filiais.map((v) => v.empresa)"
                            height="300"
                            :money="false"
                            :porcentagem="true"
                            :distributed="true"
                            :dataLabels="{
                                      position: 'top',
                                      orientation: 'horizontal',
                                      offsetY: -25,
                                  }"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card class="v-card-conteudo" style="position: relative">
                      <v-card-title class="v-card-titulo">
                        <v-icon size="30" color="primary" class="mr-2">mdi-chart-line-variant</v-icon> margem filiais (DIA)
                      </v-card-title>
                      <v-divider/>
                      <v-col v-if="carregando || !dashboard.vendas_filiais" cols="12" style="position: absolute; bottom: 80px" class="text-center">
                        <v-progress-circular indeterminate size="120" color="primary"></v-progress-circular>
                      </v-col>
                      <v-card-text :class="carregando ? '' : 'pa-0 px-3'">
                        <v-skeleton-loader elevation="2" v-if="carregando || !dashboard.vendas_filiais" class="ma-auto" width="100%" height="282px" type="image, image"></v-skeleton-loader>
                        <GraficoBarras
                            v-else
                            :key="JSON.stringify(dashboard.vendas_filiais)"
                            :dataSeries="[
                              {
                                  name: 'Atingimento',
                                  data: dashboard.vendas_filiais.map((v) => v.margem_dia.toFixed(2)),
                              },
                          ]"
                            :categories="dashboard.vendas_filiais.map((v) => v.empresa)"
                            height="300"
                            :money="false"
                            :porcentagem="true"
                            :distributed="true"
                            :dataLabels="{
                                      position: 'top',
                                      orientation: 'horizontal',
                                      offsetY: -25,
                                  }"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-col>
                <v-col cols="5" class="pa-0">
                  <v-col cols="12">
                    <v-card class="v-card-conteudo">
                      <v-card-title class="v-card-titulo">
                        <v-icon color="primary" class="mr-3 mb-1">mdi-podium</v-icon> Top 5 Vendedores (DIA)
                      </v-card-title>
                      <v-divider/>
                      <v-card-text :class="carregando ? 'pa-0' : 'px-0 pt-3'">
                        <v-skeleton-loader v-if="carregando || !dashboard.vendas_vendedores" type="table-tbody" />
                        <v-data-table
                            v-else
                            :items-per-page="5"
                            sort-by="venda_dia"
                            sort-desc
                            hide-default-footer
                            :items="dashboard.vendas_vendedores"
                            :headers="[
                                  // {text: 'Filial', value: 'empresa', class:'text-truncate', sortable:false},
                                  {text: 'Rk', value: 'medalha', class:'text-truncate pr-0',align:'start', sortable:false},
                                  {text: 'Vendedor', value: 'vendedor', class:'text-truncate pl-0', sortable:false},
                                  {text: 'Total de Venda', value: 'venda_dia', class:'text-truncate', align:'center' , sortable:false},
                              ]"
                        >
                          <template v-slot:item.vendedor="{item}">
                            <td class="pl-0">
                              {{item.vendedor | formataTextoNomeVendedores }}
                            </td>
                          </template>
                          <template v-slot:item.medalha="{item, index }">
                            <td class="pr-0">
                              <v-icon small v-if="index==0" color="yellow accent-4">mdi-medal</v-icon>
                              <v-icon small v-else-if="index==1" color="grey lighten-1">mdi-medal</v-icon>
                              <v-icon small v-else-if="index==2" color="brown darken-1">mdi-medal</v-icon>
                              <span v-else class="ml-2">-</span>
                            </td>
                          </template>
                          <template v-slot:item.venda_dia="{item}">
                            <td class="text-center" width="200px">
                              <v-chip small class="v-chip-top font-weight-medium text-subtitle-2">
                                {{item.venda_dia | formataDinheiro }}
                              </v-chip>
                            </td>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card class="v-card-conteudo">
                      <v-card-title class="v-card-titulo">
                        <v-icon color="primary" class="mr-3 mb-1">mdi-podium</v-icon>Top 5 Categorias (DIA)
                      </v-card-title>
                      <v-divider/>
                      <v-card-text :class="carregando ? 'pa-0' : 'px-0 pt-3'">
                        <v-skeleton-loader v-if="carregando || !dashboard.vendas_categorias" type="table-tbody" />
                        <v-data-table
                            v-else
                            sort-by="pervenda_dia"
                            sort-desc
                            :items-per-page="5"
                            hide-default-footer
                            :items="dashboard.vendas_categorias"
                            :headers="[
                                  {text: 'Rk', value: 'medalha', class:'text-truncate pr-0',align:'start', sortable:false},
                                  {text: 'Categoria', value: 'secao', class:'text-truncate pl-0', sortable:false},
                                  {text: '% Venda', value: 'pervenda_dia', class:'text-truncate' , align:'center', sortable:false},
                              ]"
                        >
                          <template v-slot:item.secao="{item }">
                            <td class="pl-0">
                              {{item.secao}}
                            </td>
                          </template>
                          <template v-slot:item.medalha="{item, index }">
                            <td class="pr-0">
                              <v-icon small v-if="index==0" color="yellow accent-4">mdi-medal</v-icon>
                              <v-icon small v-else-if="index==1" color="grey lighten-1">mdi-medal</v-icon>
                              <v-icon small v-else-if="index==2" color="brown darken-1">mdi-medal</v-icon>
                              <span v-else class="ml-2">-</span>
                            </td>
                          </template>
                          <template v-slot:item.pervenda_dia="{item}">
                            <td class="text-center" width="200px">
                              <v-chip small class="v-chip-top font-weight-medium text-subtitle-2">
                                {{item.pervenda_dia.toFixed(2)}}%
                              </v-chip>
                            </td>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-col>
                <v-col cols="12" class="pa-0" v-if="true">
                  <v-col cols="12">
                    <v-card class="marquee-wrapper" height="136px">
                      <v-card-title class="v-card-titulo"><v-icon size="30" color="primary" class="mr-2">mdi-television</v-icon> Painel de Desempenho de Vendedores (Dia) </v-card-title>
                      <v-divider/>
                      <v-col v-if="carregando || !dashboard.vendas_filiais" cols="12" class="text-center" style="position: absolute; bottom: 12px">
                        <v-progress-circular indeterminate size="30" color="primary"></v-progress-circular>
                      </v-col>
                      <v-card-text :class="carregando?'':'py-6'">
                        <v-skeleton-loader elevation="2" v-if="carregando || !dashboard.vendas_filiais" class="ma-auto" width="100%" height="52px" type="image, image"></v-skeleton-loader>
                        <div class="marquee py-1" v-if="dashboard.vendas_filiais">
                          <div v-for="(item, index) in repeatedItemsDia" :key="index" class="marquee-item">
                            {{ item.empresa }} - {{ item.vendedor | formataTextoNomeVendedoresCurto }} {{ (item.atingimento_dia ).toFixed(2) }}%
                            <v-icon v-if="item.venda_dia / item.meta_hora > 1" color="success" class="mb-1">mdi-arrow-up-bold</v-icon>
                            <v-icon v-else-if="item.venda_dia / (item.meta_hora-(item.meta_dia/10)) > 1" color="warning" class="mb-1">mdi-minus-thick</v-icon>
                            <v-icon v-else color="error" class="mb-1">mdi-arrow-down-bold</v-icon>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-col>
              </v-row>
            </transition>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <v-card class="primary" height="100%" width="100%" dark>
        <v-card-title>Dashboard TV</v-card-title>
        <v-divider/>
        <v-card-text class="d-flex justify-center">
          <v-btn @click="openFullscreen()"><v-icon class="pr-1">mdi-arrow-expand-all</v-icon>Abrir Dashboard</v-btn>
        </v-card-text>
      </v-card>
    </v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import GraficoBarras from "../Widgets/GraficoBarras.vue";
import GraficoPizza from "../Widgets/GraficoPizza.vue";
import GraficoPonteiro from "../Widgets/GraficoPonteiro.vue";
import axios from "axios";

export default {
	name: "DashboardTV",
	components: { GraficoBarras, GraficoPizza, GraficoPonteiro},
	data: () => ({
    marqueeKey: 0,
    shouldAnimate: false,
    flagDia: false,
    tab: 0,
    dashboard: {},

    intervalId: null,
    idregional: 0,
    regionais: ['ORLANDO PEREIRA','JULIANO DIAS','LUIS EDUARDO','DONIZETE FRANCA','DOUGLAS JOSLIN','DIYOVANNO BOTINI','SERGIO MIRA'],

    countdown: 0,
    countdownId: 1200,
    isDisabled: false,

    dialog: { status: false, dados: [], header: {} },
    carregando: false,
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
    repeatedItems() {
      // Faz uma cópia do array antes de ordenar e manipular os dados
      let dados;
        dados = [...this.dashboard.vendas_vendedores]
            .sort((a, b) => {
              return b.atingimento - a.atingimento;   // Ordena por 'venda' em ordem decrescente
            })
            .slice(0, 10)  // Pega apenas os primeiros 10 itens
            .map((v) => {
              return {
                empresa: `F${v.idempresa}`,
                vendedor: v.vendedor,
                atingimento: v.venda / v.meta,
                atingimento_dia: v.venda / v.meta_ac_dia,
                atingimento_menos_dia: v.venda / (v.meta_ac_dia - v.meta_dia),
              };
            });

      return dados;
    },
    repeatedItemsDia() {
      // Faz uma cópia do array antes de ordenar e manipular os dados
      let dados;
        dados = [...this.dashboard.vendas_vendedores]
            .sort((a, b) => {
              return b.atingimento_dia - a.atingimento_dia;  // Ordena por 'venda' em ordem decrescente
            })
            .slice(0, 10)  // Pega apenas os primeiros 10 itens
            .map((v) => {
              return {
                empresa: `F${v.idempresa}`,
                vendedor: v.vendedor,
                atingimento: v.venda / v.meta,
                atingimento_dia: v.atingimento_dia,
                meta_dia: v.meta_dia,
                meta_hora: this.calcularMetaAcumulada(v.meta_dia),
                venda: v.venda,
                venda_dia: v.venda_dia,
              };
            });

      return dados;
    }
  },
	methods: {
    openFullscreen() {
      // Abre o dialog
      this.dialog.status = true;

      // Tenta colocar a página em tela cheia (F11)
      const elem = document.documentElement; // ou document.body

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) { // Firefox
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) { // Chrome, Safari e Opera
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { // Internet Explorer/Edge
        elem.msRequestFullscreen();
      }

      this.idregional = 0;
      this.listar('+');
    },
    calcularMetaAcumulada(meta_dia) {
      const aberturaLoja = 8;  // A loja abre às 8h
      const fechamentoLoja = 18;  // A loja fecha às 18h
      const horasTotais = fechamentoLoja - aberturaLoja;  // Total de horas de operação
      const agora = new Date();  // Hora atual
      const horaAtual = agora.getHours();  // Obtém a hora atual

      // Calcula quantas horas se passaram desde a abertura da loja
      const horasPassadas = horaAtual - (aberturaLoja - 1);

      // Meta por hora
      const metaPorHora = meta_dia / horasTotais;

      // Meta acumulada até o momento
      const metaAcumulada = metaPorHora * horasPassadas;

      return metaAcumulada;
    },
    startCountdown() {
      this.isDisabled = true;
      this.intervalId = setInterval(() => {
        if (this.countdown <= this.countdownId) {
          this.countdown++;
          if (this.countdown >= (this.countdownId/2) && this.countdown <= this.countdownId) {
            this.tab=1;
            this.shouldAnimate=true;
            this.flagDia = true;
          } else {
            this.tab=0;
            this.flagDia = false;
          }
        } else {
          this.listar('+');
          clearInterval(this.intervalId);
          this.isDisabled = true;
        }
      }, 100);
    },
    resetCountdown(tab, tipo) {
      clearInterval(this.intervalId);
      if (tab == 0) {
        this.countdown = 0;
        if (tipo=='M') {
          this.shouldAnimate=true;
        } else {
          this.shouldAnimate=false;
        }
      } else {
        this.shouldAnimate=true;
        this.countdown = (this.countdownId/2);
      }
      setTimeout(() => {
        this.startCountdown();
      }, 500);
    },

    listar(automatico){
      clearInterval(this.intervalId);
      this.tab=0;
      this.flagDia = false;
      this.carregando = true;
      this.dashboard={};
      if (automatico=='+'){
        this.idregional=this.idregional==7?1:this.idregional+=1;
      } else if (automatico=='-'){
        this.idregional=this.idregional==1?7:this.idregional-=1;
      } else {
        if ( this.idregional < 7) {
          this.idregional+=1;
        } else {
          this.idregional=1
        }
      }
      return axios
        .post(`${this.backendUrl}dashboard/TV/listar`, {
          idregional: this.idregional,
        })
        .then((res) => {
          if (res) {
            this.dashboard= {};
            this.dashboard = res.data
            this.carregando = false;
            this.resetCountdown(0, 'A');
            this.marqueeKey += 1;
          } else {
            this.dashboard = res.data
            this.carregando = false;
          }
        })
        .catch(() => {
          this.carregando = false;
          window.location.reload(); // Recarrega a página
        });
    },
	},
  mounted() {
    // Chama listar() imediatamente quando o componente é montado
    this.openFullscreen();
  },
  beforeDestroy() {
    // Limpa o intervalo quando o componente é destruído
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped>
.fundo {
  background-color: #13456B;
  height: 100% !important;
}

.v-card-conteudo {
  margin: 0px !important;
}

.v-card-titulo {
  justify-content: center !important;
  text-transform: uppercase !important;
  padding: 8px 16px;
  font-weight: bold;
  color: #13456B;
}

.v-card-titulo-regional {
  font-size: 22px;
  justify-content: center !important;
  text-transform: uppercase !important;
  padding: 8px 16px;
  font-weight: bold;
  color: #FFFFFF;
}

.v-card-logo {
  border: rgba(0, 0, 0, 0.25) 1px solid;
  background-color: #0D2E49;
}

.v-card-regional {
  background-color: #3D7AA8;
}

::v-deep .v-col {
  padding: 0px !important;
}

.v-chip-top {
  background-color: #13456B !important;
  color: #FFFFFF;
}

.marquee-wrapper {
  width: 100%;
  overflow: hidden;
  border: 1px solid #ccc;
  white-space: nowrap;
  position: relative;
}

.marquee {
  display: inline-block;
  animation: marquee 60s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(25%);
  }
  100% {
    transform: translateX(-96%);
  }
}

.marquee-item {
  text-transform: uppercase !important;
  display: inline-block;
  font-size: 24px; /* Tamanho do texto */
  font-weight: bold !important;
  color: #13456B;
  margin-right: 40px; /* Espaçamento entre os itens */
}

.slide-up-enter-active, .slide-up-leave-active {
  transition: all 0.9s ease-in-out;
}
.slide-up-enter, .slide-up-leave-to {
  transform: translateY(60px);
  opacity: 0;
}
</style>