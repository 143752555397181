<template>
	<VueApexCharts type="pie" :height="height" :options="chartOptions" :series="series"></VueApexCharts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
	name: "GraficoPizza",
	components: { VueApexCharts },
	props: ["dataSeries", "labels", "palette", "height"],
	data: () => ({
		series: [],
    chartOptions: {
      chart: {
        type: "pie",
      },
      legend: {
        show: true,
        position: 'bottom', // Coloca as labels na parte inferior
        horizontalAlign: 'center', // Centraliza as labels horizontalmente
      },
      theme: {
        palette: "",
      },
      colors: ["#195C8F", "#4B606F","#50A2E0","#C5E0F5","#002D56"],
      labels: [],
      responsive: [
        {
          breakpoint: 700,
          options: {
            legend: {
              show: false,
            },
            chart: {
              type: "pie",
              height: 320,
            },
          },
        },
      ],
      dataLabels: {
        style: {
          fontSize: '18px', // Aumenta o tamanho da fonte dos valores
          fontWeight: 'bold', // Deixa os valores em negrito
          colors: ["#002D56"], // Cor dos valores
          textAnchor: 'middle' // Centraliza o texto
        },
        background: {
          enabled: true,
          borderColor: "#fff",
          opacity: 0.6,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: 15, // Move as labels para fora
            minAngleToShowLabel: 5,
          },
        },
      },
    },
	}),
	methods: {
		init() {
			this.series = this.dataSeries;
			this.chartOptions.theme.palette = this.palette;
			this.chartOptions.labels = this.labels;
		},
	},
	created() {
		this.init();
	},
};
</script>
